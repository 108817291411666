import {
  Box,
  Center,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library/components/ImageLoaderHandler";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import NextLink from "next/link";
import { MdOutlineArrowForward } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/constants/prestations").PrestationHolderTypeDefinition} holderTypeDefinition
 *
 * @param {Props} props
 */
function PrestationHolderType({ holderTypeDefinition }) {
  const intl = useIntl();

  return (
    <NextLink
      href={generateFrontPath({
        id: "@prestations.search",
        query: {
          holder_type: [holderTypeDefinition.value],
        },
      })}
      passHref>
      <VStack
        as="a"
        transition="all .25s ease"
        pb="1rem"
        role="group"
        _hover={{
          transform: "scale(1.05)",
        }}>
        <ImageLoaderHandler
          src={generateFrontPath({
            id: "internal-assets",
            parameters: { filePath: holderTypeDefinition.frontSrc },
            includeBasePath: true,
          })}>
          {({ ref, src, handleLoad, isLoaded }) => (
            <Box
              position="relative"
              borderRadius="full"
              transition="all 0.25s ease"
              _groupHover={{
                transform: "scale(1.05)",
              }}>
              <Image
                ref={ref}
                src={src}
                onLoad={handleLoad}
                opacity={isLoaded ? 1 : 0}
                width={288}
                height={288}
                borderWidth="1px"
                borderColor="red"
                borderRadius="full"
                boxShadow="#e2e8f0 0px 0px 0px 1px"
                alt={intl.formatMessage(holderTypeDefinition.label)}
              />

              <Center
                position="absolute"
                inset="30px"
                background="linear-gradient(125deg, rgba(0, 163, 213, 0.80) 20.6%, rgba(0, 163, 213, 0.80) 43.87%, rgba(69, 200, 208, 0.80) 73.78%)"
                borderRadius="full"
                opacity={0}
                transition="all 0.25s ease"
                transform="scale(0.9)"
                _groupHover={{
                  opacity: 1,
                  transform: "scale(1)",
                }}>
                <HStack>
                  <Text
                    fontSize="1.25rem"
                    fontWeight={500}
                    lineHeight="1.75rem"
                    color="#fff"
                    textAlign="center">
                    <FormattedMessage defaultMessage="Voir les activités" />
                  </Text>

                  <Icon as={MdOutlineArrowForward} color="#fff" />
                </HStack>
              </Center>
            </Box>
          )}
        </ImageLoaderHandler>

        <Text
          fontSize="1.25rem"
          fontWeight={500}
          lineHeight="1.75rem"
          textAlign="center">
          {capitalize(intl.formatMessage(holderTypeDefinition.label))}
        </Text>

        <Text
          fontSize="1rem"
          fontWeight={400}
          lineHeight="1.5rem"
          color="gray.500"
          textAlign="center">
          {capitalize(intl.formatMessage(holderTypeDefinition.ageRange))}
        </Text>
      </VStack>
    </NextLink>
  );
}

export default PrestationHolderType;
