import {
  AspectRatio,
  Box,
  DarkMode,
  HStack,
  Icon,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import LineLimiter from "@raiden/library/components/LineLimit";
import useInterpolateTranslatedField from "@raiden/library/hooks/useInterpolateTranslatedField";
import useLocale from "@raiden/library/hooks/useLocale";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import generatePampaUri from "@raiden/library/libraries/utils/generatePampaUri";
import { motion } from "framer-motion";
import NextLink from "next/link";
import { useState } from "react";
import { MdOutlineArrowForward } from "react-icons/md";
import { FormattedDate, FormattedMessage } from "react-intl";
import { getPostSlug } from "../../../helpers/posts/getPostSlug";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Post").Post} post
 *
 * @param {Props} props
 */
function ArticleCard({ post }) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const translate = useInterpolateTranslatedField();

  const { locale, defaultLocale } = useLocale();

  return (
    <NextLink
      href={generateFrontPath({
        id: "@articles.view",
        parameters: {
          articleSlug: getPostSlug({ post, locale, defaultLocale }),
        },
      })}
      passHref>
      <Box as="a" display="block">
        <AspectRatio ratio={16 / 9}>
          <Box>
            <Box
              flexGrow={0}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              position="relative"
              borderRadius="8px"
              overflow="hidden"
              role="group"
              h="full"
              w="full"
              borderWidth="1px"
              _before={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: post.current_revision?.cover
                  ? generatePampaUri({
                      image: post.current_revision?.cover,
                      mode: "crop",
                      width: 650,
                    })
                  : generateFrontPath({
                      id: "internal-assets",
                      parameters: {
                        filePath: "placeholder-news.svg",
                      },
                      includeBasePath: true,
                    }),
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}>
              <Stack position="relative" justify="flex-end" height="full">
                <Stack
                  spacing=".25rem"
                  backdropFilter="blur(4px)"
                  backgroundColor="rgba(0,0,0,.5)"
                  p="1rem">
                  <DarkMode>
                    {post.current_revision?.title && (
                      <Text
                        fontSize="1.125rem"
                        fontWeight={400}
                        lineHeight="28px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap">
                        {translate(post.current_revision.title)}
                      </Text>
                    )}

                    {post.current_revision?.summary && (
                      <LineLimiter limit={1}>
                        <Text
                          whiteSpace="pre"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          sx={{ "&": { paintOrder: "" } }}>
                          {translate(post.current_revision.summary)}
                        </Text>
                      </LineLimiter>
                    )}
                  </DarkMode>

                  <Box>
                    <HStack>
                      {post.publication_date && (
                        <DarkMode>
                          <Text
                            fontSize="0.75rem"
                            fontWeight={400}
                            lineHeight="1rem">
                            <FormattedDate
                              value={post.publication_date}
                              day="numeric"
                              month="long"
                            />
                          </Text>
                        </DarkMode>
                      )}

                      {post.categories?.map((category) => (
                        <Tag
                          variant="solid"
                          size="sm"
                          colorScheme="brandPrimary"
                          borderRadius="full"
                          key={category.id}>
                          {translate(category.name)}
                        </Tag>
                      ))}
                    </HStack>

                    <motion.div
                      animate={{ height: isMouseOver ? "auto" : 0 }}
                      initial={false}>
                      <Box pt="1rem">
                        <HStack justify="flex-end">
                          <Text
                            fontSize="1.25rem"
                            fontWeight={500}
                            lineHeight="1.75rem"
                            color="#fff"
                            textAlign="center">
                            <FormattedMessage defaultMessage="Lire l'article" />
                          </Text>

                          <Icon as={MdOutlineArrowForward} color="#fff" />
                        </HStack>
                      </Box>
                    </motion.div>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </AspectRatio>
      </Box>
    </NextLink>
  );
}

export default ArticleCard;
