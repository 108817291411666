import {
  POSTS_TYPE_VALUE_ARTICLE,
  POSTS_SORT_VALUE_PUBLICATION_DATE_DESC,
} from "@raiden/library/constants/posts";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { useApi } from "@raiden/library/hooks/useApi";
import { serverApiFetcher } from "@raiden/library/hooks/useApiFetcher";
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";
import { useIntl } from "react-intl";
import Seo from "../components/Seo";
import Home from "../containers/Home";

/**
 * @typedef {object} PageProps
 * @property {import("@raiden/library/types/Api/ApiResponse").ApiResponse<import("@raiden/library/types/Post").Post[]>} [articlesResponse]
 * @property {import("@raiden/library/types/Api/ApiResponse").ApiResponse<import("@raiden/library/types/PrestationCategory").PrestationCategory[]>} [prestationCategoriesResponse]
 */

/**
 * @param {object} params
 * @param {number} params.environmentId
 */
function getPrestationCategoriesUrl({ environmentId }) {
  return generateApiUri({
    id: "@prestations.categories.viewAny",
    query: {
      environment_id: [environmentId],
      per_page: 150,
      fields: ["prestation.category.cover"],
    },
  });
}

/**
 * @param {object} params
 * @param {number} params.environmentId
 */
function getArticlesUrl({ environmentId }) {
  return generateApiUri({
    id: "@posts.search",
    query: {
      environment_id: [environmentId],
      type: [POSTS_TYPE_VALUE_ARTICLE],
      sort: POSTS_SORT_VALUE_PUBLICATION_DATE_DESC,
      per_page: 3,
      fields: [
        "post.current_revision",
        "post.categories",
        "post.revision.cover",
      ],
    },
  });
}

/**
 * @param {PageProps} params
 */
function Page({ articlesResponse, prestationCategoriesResponse }) {
  const intl = useIntl();

  const { bestEnvironment } = usePreferences();

  /** @type {import("@raiden/library/hooks/useApi").UseApi<import("@raiden/library/types/Post").Post[]>} */
  const { swrResponse: swrResponseArticles } = useApi(
    bestEnvironment
      ? getArticlesUrl({ environmentId: bestEnvironment.id })
      : null,
    {
      swrConfig: {
        fallbackData: articlesResponse,
      },
    },
  );

  /** @type {import("@raiden/library/hooks/useApi").UseApi<import("@raiden/library/types/PrestationCategory").PrestationCategory[]>} */
  const { swrResponse: swrResponsePrestationCategories } = useApi(
    bestEnvironment
      ? getPrestationCategoriesUrl({ environmentId: bestEnvironment.id })
      : null,
    {
      swrConfig: {
        fallbackData: prestationCategoriesResponse,
      },
    },
  );

  return (
    <>
      <Seo
        title={intl.formatMessage({
          defaultMessage: "Accueil",
        })}
      />

      <Home
        swrResponseArticles={swrResponseArticles}
        swrResponsePrestationCategories={swrResponsePrestationCategories}
      />
    </>
  );
}

export default Page;

/**
 * @param {import("../types/AppContext").PageContext} context
 *
 * @returns {Promise<import("../types/Page").PageInitialProps & PageProps>}
 */
Page.getInitialProps = async function (context) {
  /** @type {import("../types/Page").PageInitialProps & PageProps} */
  const values = {
    layout: "public",
  };

  if (typeof window === "undefined") {
    const bestEnvironment =
      context.configuration?.environments?.length === 1
        ? context.configuration.environments[0]
        : undefined;

    if (bestEnvironment) {
      values.articlesResponse = await serverApiFetcher(
        getArticlesUrl({ environmentId: bestEnvironment.id }),
      ).catch(() => {});

      values.prestationCategoriesResponse = await serverApiFetcher(
        getPrestationCategoriesUrl({ environmentId: bestEnvironment.id }),
      ).catch(() => {});
    }
  }

  return values;
};
