import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import RGrid from "@raiden/library/components/RGrid";
import { PRESTATIONS_HOLDER_TYPES_LIST } from "@raiden/library/constants/prestations";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { MdOutlineArrowForward } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import ArticleCard from "../../components/Articles/Card";
import CenteredContent from "../../components/CenteredContent";
import LandingSectionTitle from "../../components/LandingSectionTitle";
import SWRHandler from "../../components/SWR/SWRHandler";
import WaveContainer from "../../components/WaveContainer/WaveContainer";
import LandingHome from "./LandingHome";
import PrestationCategoryCard from "./PrestationCategoryCard";
import PrestationHolderType from "./PrestationHolderType";

/**
 * @typedef {Object} Props
 * @property {import("swr").SWRResponse<import("@raiden/library/types/Api/ApiResponse").ApiResponse<import("@raiden/library/types/Post").Post[]>>} swrResponseArticles
 * @property {import("swr").SWRResponse<import("@raiden/library/types/Api/ApiResponse").ApiResponse<import("@raiden/library/types/PrestationCategory").PrestationCategory[]>>} swrResponsePrestationCategories
 *
 * @param {Props} props
 */
function Home({ swrResponseArticles, swrResponsePrestationCategories }) {
  const intl = useIntl();

  return (
    <Box pb="2rem">
      <LandingHome />

      <Box px="1rem" mt="3.125rem">
        <CenteredContent maxWidth="1280px">
          <Stack spacing="2.5rem" w="full">
            <LandingSectionTitle
              title={intl.formatMessage({
                defaultMessage: "L'actualité du Centre",
              })}
              color="brandSecondary.500"
            />

            <SWRHandler swrResponse={swrResponseArticles}>
              {({ data: articles }) => (
                <>
                  {articles.length > 0 && (
                    <Stack
                      direction={{ base: "column", lg: "row" }}
                      spacing="1.5rem">
                      <Box w={{ base: "100%", lg: "65.70%" }}>
                        <ArticleCard post={articles[0]} />
                      </Box>

                      <Stack flexGrow={1} overflow="hidden">
                        {articles
                          .filter((article, index) => index > 0)
                          .map((article, index) => (
                            <ArticleCard post={article} key={index} />
                          ))}
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </SWRHandler>

            <Box>
              <Center>
                <NextLink
                  href={generateFrontPath({
                    id: "@articles.search",
                  })}
                  passHref>
                  <Button
                    as="a"
                    height="52px"
                    borderRadius="full"
                    colorScheme="brandSecondary">
                    <FormattedMessage defaultMessage="Voir toutes les actualités" />
                  </Button>
                </NextLink>
              </Center>
            </Box>
          </Stack>
        </CenteredContent>
      </Box>

      <Box mt="3.125rem">
        <WaveContainer>
          <Box backgroundColor="gray.50" px="1rem" py="3.125rem">
            <CenteredContent maxWidth="1280px">
              <Stack spacing="1.5rem" w="full">
                <LandingSectionTitle
                  title={intl.formatMessage({
                    defaultMessage: "Découvrez nos activités",
                  })}
                  description={intl.formatMessage({
                    defaultMessage: "Nos activités pour tous les âges",
                  })}
                  color="brandPrimary.500"
                />

                <RGrid minCellWidth="300px">
                  {PRESTATIONS_HOLDER_TYPES_LIST.map(
                    (prestationHolderTypeDefinition, index) => (
                      <HStack justify="center" key={index}>
                        <PrestationHolderType
                          holderTypeDefinition={prestationHolderTypeDefinition}
                        />
                      </HStack>
                    ),
                  )}
                </RGrid>
              </Stack>
            </CenteredContent>
          </Box>
        </WaveContainer>
      </Box>

      <Box px="1rem" mt="3.125rem">
        <CenteredContent maxWidth="1280px">
          <Stack spacing="1.5rem" w="full">
            <LandingSectionTitle
              title={intl.formatMessage({
                defaultMessage: "Nos activités par catégories",
              })}
              description={intl.formatMessage({
                defaultMessage: "Nos activités selon vos envies",
              })}
              color="#F17E00"
            />

            <SWRHandler swrResponse={swrResponsePrestationCategories}>
              {({ data: prestationCategories }) => (
                <RGrid gridGap="1.5rem" minCellWidth="320px">
                  {prestationCategories.map((prestationCategory, index) => (
                    <PrestationCategoryCard
                      prestationCategory={prestationCategory}
                      minH="250px"
                      key={prestationCategory.id}
                    />
                  ))}

                  <NextLink
                    href={generateFrontPath({ id: "@prestations.search" })}
                    passHref>
                    <Box
                      as="a"
                      minH="230px"
                      background="linear-gradient(125deg, #00A3D5 20.6%, rgba(0, 163, 213, 0.90) 43.87%, rgba(69, 200, 208, 0.80) 73.78%)"
                      borderRadius="0.5rem">
                      <Center h="full">
                        <VStack spacing="1rem" px="1rem" py="2rem">
                          <Text
                            fontSize="1.875rem"
                            fontWeight={400}
                            lineHeight="120%"
                            color="#fff"
                            textAlign="center">
                            <FormattedMessage defaultMessage="Découvrez toutes nos activités" />
                          </Text>

                          <HStack>
                            <Text
                              fontSize="1.25rem"
                              fontWeight={500}
                              lineHeight="1.75rem"
                              color="#fff"
                              textAlign="center">
                              <FormattedMessage defaultMessage="Voir tout" />
                            </Text>

                            <Icon as={MdOutlineArrowForward} color="#fff" />
                          </HStack>
                        </VStack>
                      </Center>
                    </Box>
                  </NextLink>
                </RGrid>
              )}
            </SWRHandler>
          </Stack>
        </CenteredContent>
      </Box>
    </Box>
  );
}

export default Home;
