import { Image } from "@chakra-ui/react";
import { memo } from "react";
import generateFrontPath from "../../libraries/utils/generateFrontPath";
import generatePampaUri from "../../libraries/utils/generatePampaUri";
import ImageLoaderHandler from "../ImageLoaderHandler";

const IMAGE_FORMATS = (process.env.NEXT_PUBLIC_PAMPA_FORMATS ?? "").split(
  /, ?/,
);
const IMAGE_WIDTHS = (process.env.NEXT_PUBLIC_DEVICE_SIZES ?? "")
  .split(/, ?/)
  .map((deviceSize) => parseInt(deviceSize));

function resolveSrcSet(
  image,
  mode,
  format,
  maxContainerWidth = IMAGE_WIDTHS[IMAGE_WIDTHS.length - 1],
) {
  const blocks = [];

  // ajout du maxContainerWidth s'il n'est pas présent dans le tableau des widths
  const initialWidths = [...IMAGE_WIDTHS];
  if (!initialWidths.includes(maxContainerWidth)) {
    initialWidths.push(maxContainerWidth);
    initialWidths.sort((a, b) => a - b);
  }

  // détermine l'ensemble des widths pertinentes pour l'image
  // ne prends pas en compte les widths qui sont supérieurs à maxContainerWidth
  const widths = [];
  for (let i = 0; i < initialWidths.length; i++) {
    const width = initialWidths[i];
    if (width <= maxContainerWidth) {
      widths.push(width);
    } else {
      break;
    }
  }

  widths.forEach((width) => {
    blocks.push(
      `${generatePampaUri({
        image,
        mode,
        format,
        width,
      })} ${width}w`,
    );
  });
  return blocks.join(", ");
}

/**
 * @param {import("@chakra-ui/react").ImageProps &{
 * image: any,
 * mode: "resize" | "crop",
 * alt: string,
 * format?: string,
 * quality?: number,
 * maxContainerWidth?: number,
 * placeholderSrc?: string,
 * }} param0
 */
function PampaImage({
  image,
  mode,
  alt,
  format,
  quality,
  maxContainerWidth,
  placeholderSrc,
  ...otherProps
}) {
  return (
    <picture>
      {image &&
        IMAGE_FORMATS.map?.((outputFormat) => (
          <source
            srcSet={resolveSrcSet(image, mode, format, maxContainerWidth)}
            type={`image/${outputFormat}`}
            key={outputFormat}
          />
        ))}

      <ImageLoaderHandler
        src={
          image
            ? generatePampaUri({
                image: image,
                mode: mode,
                format: format,
                width: maxContainerWidth,
              })
            : placeholderSrc ??
              generateFrontPath({
                id: "internal-assets",
                parameters: { filePath: "placeholder.svg" },
                includeBasePath: true,
              })
        }>
        {({ ref, src, isLoaded, handleLoad }) => (
          <Image
            ref={ref}
            src={src}
            loading="lazy"
            alt={alt}
            opacity={isLoaded ? 1 : 0}
            transition="opacity 0.25s ease"
            onLoad={handleLoad}
            {...otherProps}
          />
        )}
      </ImageLoaderHandler>
    </picture>
  );
}

export default memo(PampaImage);
