import { Box, Text, VStack } from "@chakra-ui/react";

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {string} [description]
 * @property {string} color
 *
 * @param {Props} props
 */
function LandingSectionTitle({ title, description, color }) {
  return (
    <VStack spacing="1.5rem">
      <Box
        width="120px"
        height="4px"
        margin="auto"
        borderRadius="full"
        backgroundColor={color}
      />

      <Text
        textAlign="center"
        fontSize="3rem"
        fontWeight={400}
        lineHeight="normal"
        color={color}>
        {title}
      </Text>

      {description && (
        <Text
          textAlign="center"
          fontSize="1.875rem"
          fontWeight={400}
          lineHeight="2.25rem"
          color="gray.500">
          {description}
        </Text>
      )}
    </VStack>
  );
}

export default LandingSectionTitle;
