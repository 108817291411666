import { Box } from "@chakra-ui/react";

/**
 * @typedef {Object} Props
 * @property {number} limit
 * @property {import("react").ReactNode} children
 *
 * @param {Props} props
 */
function LineLimiter({ limit, children }) {
  return (
    <Box
      sx={{
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: `${limit}`,
        display: "-webkit-box",
        lineClamp: `${limit}`,
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}>
      {children}
    </Box>
  );
}

export default LineLimiter;
