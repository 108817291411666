import { Box, Image } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import CenteredContent from "../CenteredContent";

const DEFAULT_SRC_TOP = generateFrontPath({
  id: "internal-assets",
  parameters: { filePath: "wave-gray-top.svg" },
  includeBasePath: true,
});

const DEFAULT_SRC_BOTTOM = generateFrontPath({
  id: "internal-assets",
  parameters: { filePath: "wave-gray-bottom.svg" },
  includeBasePath: true,
});

/**
 * @typedef {Object} Props
 * @property {string} [srcTop]
 * @property {string} [srcBottom]
 * @property {boolean} [preserveOnMobile]
 * @property {React.ReactNode} children
 *
 * @param {Props} props
 */
function WaveContainer({
  srcTop,
  srcBottom,
  preserveOnMobile = true,
  children,
}) {
  return (
    <Box
      pt={preserveOnMobile ? "2.5rem" : { base: undefined, lg: "2.5rem" }}
      pb={preserveOnMobile ? "2rem" : { base: undefined, lg: "2rem" }}>
      <Box
        display={preserveOnMobile ? undefined : { base: "none", lg: "block" }}>
        <CenteredContent maxWidth="1600px">
          <Box position="relative">
            <Image
              display={
                preserveOnMobile ? undefined : { base: "none", lg: "block" }
              }
              position="absolute"
              top="1px" // offset de 1px pour éviter les artefacts
              left="0"
              right="0"
              transform="translateY(-100%)"
              pointerEvents="none"
              src={DEFAULT_SRC_TOP}
              alt="wave"
            />
          </Box>
        </CenteredContent>
      </Box>

      {children}

      <Box
        display={preserveOnMobile ? undefined : { base: "none", lg: "block" }}>
        <CenteredContent maxWidth="1600px">
          <Box position="relative">
            <Image
              display={
                preserveOnMobile ? undefined : { base: "none", lg: "block" }
              }
              position="absolute"
              bottom="1px" // offset de 1px pour éviter les artefacts
              left="0"
              right="0"
              transform="translateY(100%)"
              pointerEvents="none"
              src={DEFAULT_SRC_BOTTOM}
              alt="wave"
            />
          </Box>
        </CenteredContent>
      </Box>
    </Box>
  );
}

export default WaveContainer;
