import { Box, Button, DarkMode, Image, Stack, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { FormattedMessage } from "react-intl";
import CenteredContent from "../../components/CenteredContent";
import NextLink from "next/link";

function LandingHome() {
  return (
    <DarkMode>
      <Box backgroundColor="brandPrimary.500">
        <CenteredContent maxWidth="1600px">
          <Box
            position="relative"
            backgroundImage={`url(${generateFrontPath({
              id: "internal-assets",
              parameters: { filePath: "landing.png" },
              includeBasePath: true,
            })})`}
            backgroundSize="cover"
            backgroundPosition="center">
            <Image
              position="absolute"
              bottom="-1px"
              left="0"
              right="0"
              pointerEvents="none"
              src={generateFrontPath({
                id: "internal-assets",
                parameters: { filePath: "wave-white-top.svg" },
                includeBasePath: true,
              })}
              alt="wave"
            />

            <Box
              backgroundImage={generateFrontPath({
                id: "internal-assets",
                parameters: { filePath: "bubble.svg" },
                includeBasePath: true,
              })}
              backgroundSize="auto 100%"
              backgroundRepeat="no-repeat">
              <Box px="1rem">
                <CenteredContent maxWidth="1280px">
                  <Stack
                    spacing="1.5rem"
                    maxW="650px"
                    pt={{ base: "5rem", lg: "20rem" }}
                    pb="5rem"
                    pr="3rem">
                    <Text fontSize="3rem" fontWeight={400} lineHeight="normal">
                      <FormattedMessage defaultMessage="Bienvenue au Centre Social Louise Michel de Golbey" />
                    </Text>

                    <Text
                      fontSize="1.5rem"
                      fontWeight={400}
                      lineHeight="1.8rem">
                      <FormattedMessage defaultMessage="Des activités sportives, culturelles, créatives, ... pour les résidents de Golbey et de ses alentours." />
                    </Text>

                    <Box>
                      <NextLink
                        href={generateFrontPath({
                          id: "@prestations.search",
                        })}
                        passHref>
                        <Button
                          variant="solid"
                          borderRadius="full"
                          backgroundColor="#fff"
                          color="brandSecondary.500"
                          fontSize="1.125rem"
                          fontWeight={400}
                          lineHeight="1.75rem"
                          height="52px"
                          _hover={{
                            backgroundColor: "whiteAlpha.900",
                          }}
                          _active={{
                            backgroundColor: "whiteAlpha.800",
                          }}>
                          <FormattedMessage defaultMessage="Découvrir les activités" />
                        </Button>
                      </NextLink>
                    </Box>
                  </Stack>
                </CenteredContent>
              </Box>
            </Box>
          </Box>
        </CenteredContent>
      </Box>
    </DarkMode>
  );
}

export default LandingHome;
