import { Box, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import PampaImage from "@raiden/library/components/PampaImage";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { motion } from "framer-motion";
import NextLink from "next/link";
import { useState } from "react";
import { MdOutlineArrowForward } from "react-icons/md";
import { FormattedMessage } from "react-intl";

const TEXT_SHADOW = "0 0 1px #1A202C";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/PrestationCategory").PrestationCategory} prestationCategory
 *
 * @param {import("@chakra-ui/react").BoxProps & Props} props
 */
function PrestationCategoryCard({ prestationCategory, ...otherProps }) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <NextLink
      href={generateFrontPath({
        id: "@prestations.search",
        query: { category_id: [prestationCategory.id] },
      })}
      passHref>
      <Box
        as="a"
        position="relative"
        borderRadius="0.5rem"
        overflow="hidden"
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        {...otherProps}>
        <PampaImage
          image={prestationCategory.cover}
          mode="crop"
          placeholderSrc={generateFrontPath({
            id: "internal-assets",
            parameters: { filePath: "placeholder-subscription.svg" },
            includeBasePath: true,
          })}
          objectFit="cover"
          position="absolute"
          top={0}
          left={0}
          height="full"
          width="full"
          alt={prestationCategory.name}
        />

        <Box h="full">
          <Box
            position="absolute"
            inset="0"
            backgroundColor="gray.700"
            opacity=".3"
          />

          <Stack justify="flex-end" position="relative" h="full" px="6rem">
            <Text
              fontSize="1.875rem"
              fontWeight={400}
              lineHeight="120%"
              color="#fff"
              textAlign="center"
              pb="30px"
              textShadow={TEXT_SHADOW}>
              {prestationCategory.name}
            </Text>

            <motion.div
              animate={{
                height: isMouseOver ? "auto" : 0,
              }}
              initial={false}>
              <Box pb="2rem">
                <HStack justify="center">
                  <Text
                    fontSize="1.25rem"
                    fontWeight={500}
                    lineHeight="1.75rem"
                    color="#fff"
                    textAlign="center"
                    textShadow={TEXT_SHADOW}>
                    <FormattedMessage defaultMessage="Voir le détail" />
                  </Text>

                  <Icon as={MdOutlineArrowForward} color="#fff" />
                </HStack>
              </Box>
            </motion.div>
          </Stack>
        </Box>
      </Box>
    </NextLink>
  );
}

export default PrestationCategoryCard;
