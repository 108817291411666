/**
 * @param {object} params
 * @param {import("@raiden/library/types/Post").Post} params.post
 * @param {string} [params.locale]
 * @param {string} [params.defaultLocale]
 * @returns {string}
 */
export function getPostSlug({ post, locale, defaultLocale }) {
  return `${post.id}-${
    (locale ? post.current_revision?.slug[locale] : undefined) ??
    (defaultLocale ? post.current_revision?.slug[defaultLocale] : undefined) ??
    ""
  }`;
}
